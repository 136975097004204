import React from "react";
import { Link } from "react-router-dom";

function LinkNextPresentation({id}) {
    const nextPresentationId = Number(id) + 1;

    if (id === "131") {
        return <Link to={`/programma/201`} className="singleRight button" preventScrollReset={true}>Volgende presentatie</Link>
    } else if (id === "231") {
    } else {
        return <Link to={`/programma/${nextPresentationId}`} className="singleRight button" preventScrollReset={true}>Volgende presentatie</Link>
    }
}

export default LinkNextPresentation;