import React from 'react';
import { Link } from 'react-router-dom';

const Hero = ({heroTitle}) => {
return (
    <header className="hero">
        <h1>{heroTitle}</h1>
        <h2>25 & 26 november 2024 | Congrescentrum 1931 &#39;s-Hertogenbosch</h2>
        <Link to="/deelname" className="button">Inschrijven</Link>
    </header>
)
}

export default Hero;