import React from "react";
import Hero from "../components/Hero";

const Deelname = () => {
    return (
        <div className="container-deelname">
            <Hero heroTitle={"Deelname"}/>
            <iframe src="https://shop.yourticketprovider.nl/1b57f365-ccfb-83f4-c977-1bfc31daa377" className="ticketFrame"></iframe>
        </div>
    )
}

export default Deelname;