import React from "react";
import imagesWinkel from "../assets/imagesWinkel";

const WinkelCard = ({productObject, addToCart}) => {
    return (
        <div className="winkelCard">
            <div className="winkelCard__preview">
                <img src={imagesWinkel[productObject.afbeelding]}/>
                <p className="winkelCard__prijs">€ {productObject.prijs},-</p>
            </div>
            <div className="winkelCard__content">
                <h3>{productObject.product}</h3>
                <p className="winkelCard__location">{productObject.beschrijving}</p>
                <div className="winkelCard__bottom">
                    <button className="winkelCard__link" onClick={() => addToCart(productObject)}>Toevoegen</button>
                </div>
            </div>
        </div>
    )
}

export default WinkelCard;