import React, { useState } from "react";
import Hero from "../components/Hero";
import PresentatieCard from "../components/PresentatieCard";
import fallbackSrc from "../assets/Logo-geobuzz-geel.jpg";

const Programma = (props) => {
    const [filterObject, setFilterObject] = useState();

    const filterReset = (e) => {
        console.log(e.target.value);
        setFilterObject();
    }
    
    const handleClickDate = (e) => {
        const filterArray = props.data.filter(presentation => 
            presentation.datum == e.target.value)
        setFilterObject(filterArray);
    };

    const handleClickTime = (e) => {
        const filterArray = props.data.filter(presentation => 
            presentation.tijdstip == e.target.value)
        if (e.target.value == "12.00 - 12.45") {
            props.data.forEach(presentation => {
                if (presentation.tijdstip == "12.00 - 12.20") {
                    filterArray.push(presentation);
                } else if (presentation.tijdstip == "12.25 - 12.45") {
                    filterArray.push(presentation);
                }
            })
        } else if (e.target.value == "13.45 - 14.30") {
            props.data.forEach(presentation => {
                if (presentation.tijdstip == "13.45 - 14.05") {
                    filterArray.push(presentation);
                } else if (presentation.tijdstip == "14.10 - 14.30") {
                    filterArray.push(presentation);
                }
            })
        } else if (e.target.value == "15.00 - 15.45") {
            props.data.forEach(presentation => {
                if (presentation.tijdstip == "15.00 - 15.20") {
                    filterArray.push(presentation);
                } else if (presentation.tijdstip == "15.25 - 15.45") {
                    filterArray.push(presentation);
                }
            })
        }

        console.log(filterArray);
        setFilterObject(filterArray);
    };


    return (
        <div className="container-programma">
            <Hero heroTitle={"Programma"}/>
            <h2 className="header_info1">Bekijk het programma</h2>

            <div className="filter">
                <button type="button" onClick={filterReset} value="Alle">Alle</button>
                <button type="button" onClick={handleClickDate} value="25 november">25 november</button>
                <button type="button" onClick={handleClickDate} value="26 november">26 november</button>
                <button type="button" onClick={handleClickTime} value="12.00 - 12.45">12.00 - 12.45</button>
                <button type="button" onClick={handleClickTime} value="13.45 - 14.30">13.45 - 14.30</button>
                <button type="button" onClick={handleClickTime} value="15.00 - 15.45">15.00 - 15.45</button>

            </div>
            
            <div className="cointainer-card">
                {
                    filterObject ?
                    filterObject.map(presentation => {
                        return(
                            <PresentatieCard 
                                key={presentation["Presentatie ID"]} 
                                presentationObject={presentation}
                                imageSource={presentation.afbeelding}
                                fallbackSrc={fallbackSrc}
                            />
                        )
                    })
                    :
                    props.data.map(presentation => {
                        return(
                            <PresentatieCard 
                                key={presentation["Presentatie ID"]} 
                                presentationObject={presentation}
                                imageSource={presentation.afbeelding}
                                fallbackSrc={fallbackSrc}
                            />
                        )
                    })
                }
            </div>
        </div>
    )
}

export default Programma;