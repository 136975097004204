import React from 'react';
import LinkedInLogo from "../assets/linkedin-logo.svg";
import TwitterLogo from "../assets/twitter-logo.svg";

const Footer = () => {
    return (
        <footer>
            <div className="footer-container">
                <div className="footer-container-sub">
                    <h3>Organisatie</h3>
                    <p>GeoBuzz wordt georganiseerd door <a href="https://sense-events.nl/" target="_blank">Sense Events</a>, i.s.m. <a href="https://geoinformatienederland.nl/" target="_blank">Geo-Informatie Nederland</a> en <a href="https://www.geobusiness.nl/" target="_blank">GeoBusiness Nederland</a></p>
                    <p>T: 06 53 29 77 41 / 06 34 73 73 22</p>
                </div>
                <div>
                    <h3>Volg ons</h3>
                    <div className="footer-social-logo-container">
                        <a href="https://www.linkedin.com/company/geobuzz" target="_blank"><img src={LinkedInLogo} className="footer-social-logo" /></a>
                        <a href="https://geobuzz.nl/#GeoBuzz_Event" target="_blank"><img src={TwitterLogo} className="footer-social-logo"/></a>
                    </div>                
                </div>
            </div>
        </footer>
    )
}

export default Footer;