import React from "react";
import { Link } from "react-router-dom";

function LinkPrevPresentation({id}) {
    const nextPresentationId = Number(id) - 1;

    if (id === "201") {
        return <Link to={`/programma/131`} className="singleLeft button" preventScrollReset={true}>Vorige presentatie</Link>
    } else if (id === "101") {
    } else {
        return <Link to={`/programma/${nextPresentationId}`} className="singleLeft button" preventScrollReset={true}>Vorige presentatie</Link>
    }
}

export default LinkPrevPresentation;