import React from "react";
import GIN from "../assets/GIN-website.webp";
import GBNL from "../assets/GeobusinessNL-website.webp";
import Sense from "../assets/Sense-website.webp";

const Partners = () => {
    return (
        <div className="partners">
            <h2>Partners</h2>
            <div className="partners-logo">
                <a href="https://geoinformatienederland.nl/" target="_blank"><img src={GIN}></img></a>
                <a href="https://www.geobusiness.nl/" target="_blank"><img src={GBNL}></img></a>
                <a href="https://www.sense-events.nl" target="_blank"><img src={Sense}></img></a>
            </div>
        </div>
    )
};

export default Partners;