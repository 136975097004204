import React from "react";
import Hero from "../components/Hero";
import { Link } from "react-router-dom";

const Handboek = () => {
    return (
        <div className="handboek__container">
            <Hero heroTitle="Beurs handboek"/>
            <div className="handboek__content">
                <div>
                    <h3>Contact organisatie</h3>
                    <p>GeoBuzz wordt georganiseerd door Sense Events. Uw contactpersonen bij Sense zijn:<br /><br />
                    Tom ter Veen<br />
                    T: 06 – 34737322; E: info@geobuzz.nl<br />
                    <br />
                    Herman ter Veen<br />
                    T: 06 – 53297741; E: info@geobuzz.nl</p>
                </div>
                <div>
                    <h3>Locatie / Parkeren</h3>
                    <p>GeoBuzz vindt plaats in Congrescentrum 1931: Oude Engelenseweg 1, 5222 AA ’s-Hertogenbosch. W: www.1931.nl; T: 088-9000333; Onze contactpersoon bij 1931 is Michelle van Heiningen.<br />
                    <br />
                    Parkeren<br />
                    Er zijn voldoende parkeerplaatsen bij 1931. Vanaf de parkeerplaats is het eenvoudig om standmateriaal 1931 in te brengen. Via grote deuren krijgt u bij opbouw- en afbouwdagen toegang tot de hal waarin de beurs is. Er mag geen auto of aanhanger de hal ingereden worden. Het materiaal kan wel met eigen karren naar binnen gereden worden.<br />
                    <br />
                    Tijdens de opbouw en afbouw kunt u zonder parkeerkaart het parkeerterrein verlaten. Tijdens het evenement dient u een parkeerkaart te kopen.</p>
                </div>
                <div>
                    <h3>Beursindeling</h3>
                    <p>De actuele beursplattegrond kunt u <Link to="/beurs">hier bekijken</Link>. In principe wordt deze beursindeling niet veranderd. 
                        Er kunnen zich echter omstandigheden voordoen waardoor de GeoBuzz organisatie genoodzaakt is de indeling aan te passen. 
                        Als dit gevolgen heeft voor uw standplaats dan wordt hierover met u overleg gepleegd.</p>
                </div>
                <div>
                    <h3>Wat omvangt uw beursplaats</h3>
                    <p>Alle beursplaatsen zijn voor de dagen 25 & 26 november. U kunt extra materiaal bestellen in de <Link to="/winkel">winkel.</Link></p>
                    <h4>Standplaats</h4>
                    <ul>
                        <li>Een vloerruimte van 3 meter diep en 4 meter breed, of het aantal bestelde vierkante meters.</li>
                        <li>De standplaats is incl. standbouw.</li>
                        <li>1 stroompunt.</li>
                        <li>Vrij gebruik van het openbare Wifi-netwerk. Een ADSL aansluiting kan besteld worden in de <Link to="/winkel">winkel.</Link></li>
                    </ul>
                    <h4>Banner stand</h4>
                    <ul>
                        <li>Een plaats van 3 meter breed</li>
                        <li>1 statafel & 2 barkrukken</li>
                        <li>Print van 2 x 1,5 meter. U levert een afbeelding uiterlijk 1 november aan, die wij printen. De print mag u na afloop houden.</li>
                        <li>1 stroompunt.</li>
                        <li>Vrij gebruik van het openbare Wifi-netwerk. Een ADSL aansluiting kan besteld worden in de <Link to="/winkel">winkel.</Link></li>
                    </ul>
                    <h4>Praattafel</h4>
                    <ul>
                        <li>Een witte tafel: 1,6m lang en 0,6m breed</li>
                        <li>Een blauwe wand: 1m breed en 2,5m hoog met logobordje</li>
                        <li>2 barkrukken (wit)</li>
                        <li>1 stroompunt.</li>
                        <li>Vrij gebruik van het openbare Wifi-netwerk. Een ADSL aansluiting kan besteld worden in de <Link to="/winkel">winkel.</Link></li>
                    </ul>
                    <h4>Onderwijskraam</h4>
                    <ul>
                        <li>Een marktkraam van 2m breed en geel tafelblad.</li>
                        <li>1 stroompunt.</li>
                        <li>Vrij gebruik van het openbare Wifi-netwerk. Een ADSL aansluiting kan besteld worden in de <Link to="/winkel">winkel.</Link></li>
                    </ul>
                </div>
                <div>
                    <h3>Winkel</h3>
                    <p>Bekijk aanvullende diensten in de <Link to="/beurs">hier bekijken</Link>. Voor standbouw, standmateriaal en meer mogelijkheden. Deze kunt u gemakkelijk bestellen via het formulier en wij zullen deze materialen voor u verzorgen. Heeft u wensen die niet op de pagina staan? Neem contact op met info@geobuzz.nl en wij maken het mogelijk.</p>
                </div>
                <div>
                    <h3>Beursplaats definitief maken</h3>
                    <p>Uw beursplaats(en) wordt definitief nadat wij het door u ondertekende contract hebben ontvangen en de factuur is betaald. Indien dit niet gebeurt conform de termijnen die in het contract en de factuur zijn vermeld, dan blijft uw plaats(en) voorlopig en is de GeoBuzz organisatie gerechtigd uw beursplaats(en) aan een andere standhouder te geven.</p>
                </div>
                <div>
                    <h3>Verzekering</h3>
                    <p>U dient zelf uw materiaal die in het Congrescentrum 1931 staan te verzekeren. De GeoBuzz organisatie is niet verantwoordelijk voor diefstal of beschadiging van uw materiaal.</p>
                </div>
                <div>
                    <h3>Opslag materiaal</h3>
                    <p>Informeer tijdens de opbouw bij een medewerker van Sense Events waar u materiaal kunt opslaan. Uw goederen neemt u mee en laat u staan voor eigen risico.</p>
                </div>
                <div>
                    <h3>Materiaal vooraf toesturen</h3>
                    <p>Wilt u materiaal sturen naar 1931 dan is dat mogelijk. Vermeld op de verpakking duidelijk:<br />
                    <br />
                    Congrescentrum 1931<br />
                    GeoBuzz, Contactpersoon “uw naam”, t.b.v. “uw organisatienaam”<br />
                    Oude Engelenseweg 1<br />
                    5222 AA ’s-Hertogenbosch<br />
                    <br />
                    Bij de GeoBuzz informatiebalie kunt u op 25 & 26 november uw verzending afhalen. Verzendingen rechtstreeks aan 1931 is voor eigen risico. 1931 en de GeoBuzz organisatie zijn niet verantwoordelijk voor het niet aanwezig zijn van uw pakket.  Natuurlijk doen wij er alles aan om uw verzending op de juiste manier te ontvangen en aan u uit te reiken.</p>
                </div>
                <div>
                    <h3>Toegang</h3>
                    <p>GeoBuzz is voor iedereen gratis toegankelijk. U kunt zich inschrijven via <Link to="/deelname">deze pagina.</Link></p>
                </div>
                <div>
                    <h3>Op- en Afbouw</h3>
                    <p>Op 24 november is de opbouwdag. De beurshal is hiervoor open tussen 09:00 – 17:00 uur. Tijdens de opbouwdag wordt koffie en thee vrij aangeboden. Op de beursvloer staan hiervoor tappunten.<br/>
                    <br />
                    Heeft u via de congresorganisatie standbouw besteld dan staat uw stand gereed op 24 november om 16 uur.<br />
                    <br />
                    Op 25 november is het mogelijk om uw standplaats in te richten van 07:00 – 09:00 uur. De afbouw vindt plaats op 26 november van 17:30 – 22:00 uur. Indien er op 26 november na 22:00 uur nog standbouw staat haalt de organisatie de materialen weg op uw kosten.</p>
                </div>
                <div>
                    <h3>Openingstijden Beurs</h3>
                    <p>De beurs opent op 25 november om 09:00 uur. Wij verzoeken u om op dat tijdstip uw stand ingericht te hebben en het verpakkingsmateriaal te hebben opgeruimd. De beurs sluit op 26 november om 17:30 uur.</p>
                </div>
                <div>
                    <h3>Zaalhuur</h3>
                    <p>Er zijn vergaderzalen beschikbaar voor vergaderingen/bijeenkomsten. De zalen hebben een capaciteit van 10 – 16 personen (vergadertafel). Deze zalen kunnen gehuurd worden voor € 175 per dagdeel. In de zalen hangt een beeldscherm.<br />

                    Tevens is een fraaie zaal beschikbaar voor een groter overleg/bijeenkomst met een capaciteit van 70 personen (theater-opstelling). De zaalhuur is € 300 per dagdeel.</p>
                </div>
                <div>
                    <h3>Communicatie</h3>
                    <p>Wij vragen u om actief zelf mee te communiceren over GeoBuzz. Wij stellen hiervoor materiaal beschikbaar. Heeft u nieuws over uw bijdrage aan GeoBuzz laat het dan weten aan ons. Dan vermelden wij dat via Twitter, LinkedIn en/of de digitale nieuwsbrief.<br />
                    <br />
                    Wilt u het GeoBuzz logo met een link naar www.geobuzz.nl plaatsen op uw website en onze LinkedIn posts reposten? U kunt onze digitale nieuwsbrieven doorsturen aan uw realaties. Als u zelf nieuwsbrieven uitgeeft n.a.v. uw bijdrage aan GeoBuzz kunnen wij die doorsturen. Laat ons weten wat u communiceert.</p>
                </div>
            </div>
        </div>
    )
};

export default Handboek;