import React from "react";
import Foto1 from "../assets/geobuzz-home-1.jpg";
import NieuwsbriefAanmelden from "../components/NieuwsbriefAanmelden";
import Hero from "../components/Hero";

import { Link } from "react-router-dom";

const Home = () => {
    return (
        <div className="container-home">
            <Hero heroTitle={"GeoBuzz"} />
            <div className="header_info1">
                <h2>OVER GEOBUZZ</h2>
                <div className="separator"></div>
            </div>

            <p className="info1">GeoBuzz is het jaarlijkse congres van de verenigingen GIN (GeoInformatie Nederland) en GBN (GeoBusiness Nederland).
                Het thema van het congresprogramma is: geodata: niet uniek, wel essentieel.<br /><br />

                Op de beurs ontmoet u bedrijven en organisaties die geodata inwinnen, delen en gebruiken.
                Zij gaan graag in gesprek met u over de diensten en producten die zij aanbieden.
                De geosector is essentieel voor veel maatschappelijke thema&apos;s, voor beleid en uitvoering er van.
                Laat u bijpraten en verrassen.<br /><br />

                GeoBuzz is het evenement waar vakgenoten in de geosector elkaar ontmoeten, maar ook voor gebruikers van geodata. Ontdek wat dit voor u kan betekenen.
                Deelname aan GeoBuzz is kosteloos.<br /><br />

                Kom naar GeoBuzz en ontdek de mogelijkheden van geodata.
            </p>

            <div className="counter">
                    <div>
                        <h2>56</h2>
                        <p>exposanten</p>
                    </div>
                    <div>
                        <h2>2000</h2>
                        <p>Deelnemers</p>
                    </div>
                    <div>
                        <h2>50</h2>
                        <p>Presentaties</p>
                    </div>
            </div>
            <div className="image1">
                <img src={Foto1} />
            </div>

            <div className="home__header2">
                <h2>TIJDSCHEMA</h2>
                <div className="separator"></div>
            </div>
            <div className="home__time">
                <p>Hieronder vindt u het tijdschema voor beide beursdagen. De presentaties zijn te vinden op de <Link to="/programma">programma pagina.</Link></p><br />
                <table>
                    <tr>
                        <td>09.00 uur</td>
                        <td>Beurs open</td>
                    </tr>
                    <tr>
                        <td>10.00 - 11.15 uur</td>
                        <td>Plenaire programma</td>
                    </tr>
                    <tr>
                        <td>11.15 - 12.00 uur</td>
                        <td>Pauze</td>
                    </tr>
                    <tr>
                        <td>12.00 - 12.45 uur</td>
                        <td>Sessieblok 1</td>
                    </tr>
                    <tr>
                        <td>12.45 - 13.45 uur</td>
                        <td>Pauze</td>
                    </tr>
                    <tr>
                        <td>13.45 - 14.30 uur</td>
                        <td>Sessieblok 2</td>
                    </tr>
                    <tr>
                        <td>14.30 - 15.15 uur</td>
                        <td>Pauze</td>
                    </tr>
                    <tr>
                        <td>15.15 - 16.00 uur</td>
                        <td>Sessieblok 3</td>
                    </tr>
                    <tr>
                        <td>16.00 - 17.00 uur</td>
                        <td>Borrel</td>
                    </tr>
                </table>

            </div>

            <NieuwsbriefAanmelden />
        </div>
    )
}

export default Home;