import React, { useState } from "react"
import Hero from "../components/Hero";

const Contact = () => {
    const [newsletterFormData, setNewsletterFormData] = useState({
        email: "",
        voornaam: "",
        message: ""
    });

    const [dataSent, setDataSent] = useState(false);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewsletterFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log(newsletterFormData);
        fetch("https://geobuzz.nl/api/contact", {
            method: "POST",
            headers: {
                "Content-type": "application/json"
            },
            body: JSON.stringify(newsletterFormData)
          }).then((response) => {
            console.log("fetch call gedaan");
            setNewsletterFormData({email: "", voornaam: "", message: ""});
            console.log(response);
            if (response.ok) {
                setDataSent(true);
            }
        });
    }
    
    return (
        <div className="container-contact">
            <Hero heroTitle={"Contact"}/>
            <h2 className="titel">Neem contact met ons op</h2>
            <div className="contact">
                <form onSubmit={handleSubmit}>
                    <label for="email">E-mailadres</label>
                    <input
                        type="text"
                        name="email"
                        id="email"
                        value={newsletterFormData.email}
                        onChange={handleInputChange}
                    />
                    <label for="voornaam">Voornaam</label>
                    <input
                        type="text"
                        name="voornaam"
                        id="voornaam"
                        value={newsletterFormData.voornaam}
                        onChange={handleInputChange}
                    />
                    <label for="message">Bericht</label>
                    <textarea
                        name="message"
                        id="message"
                        value={newsletterFormData.message}
                        onChange={handleInputChange}
                    />
                    <input type="submit" />
                </form>
                {
                    dataSent && (<p>Bedankt voor uw bericht. We nemen zo snel mogelijk contact met u op</p>)
                }
            </div>
        </div>
    )
}

export default Contact;